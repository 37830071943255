import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import { ParameterCard } from "../../documented/ParameterCard";
import { PassiveParameterTypeEnum } from "../../../model/passive/enum/passiveParameterTypeEnum";
import { RadialIndicator } from "../../documented/RadialIndicator";

import getRadialConfig from "../../documented/RadialIndicator/radialConfig";
import { ComplianceOverview, CompliancePeriod } from "./ComplianceCardData";
interface IComplianceCardProps {
  onClick?: () => void;
  title: string;
  popoverContent: React.ReactNode;
  data?: ComplianceOverview;
  large?: boolean;
}

const ParamContentContainer = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
}));

const ComplianceCard: React.FC<IComplianceCardProps> = ({
  onClick = () => {},
  title,
  popoverContent,
  data,
  large,
}: IComplianceCardProps) => {
  const [previousPeriodData, setPreviousPeriodData] =
    useState<CompliancePeriod | null>(null);

  const [currentPeriodData, setCurrentPeriodData] =
    useState<CompliancePeriod | null>(null);

  useEffect(() => {
    if (data) {
      setPreviousPeriodData(data.previousPeriod);
      setCurrentPeriodData(data.currentPeriod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <ParameterCard
      large={large}
      title={title}
      popoverContent={popoverContent}
      onClick={onClick}
    >
      <Grid container direction="column">
        <Grid item>
          <Typography fontSize={14} sx={{ textAlign: "left", ml: 4 }}>
            <strong>
              {`as of ${new Date().toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}`}
            </strong>
          </Typography>
        </Grid>

        <Grid item container justifyContent="center">
          <Grid item xs={6} container direction="column" alignItems="center">
            <ParamContentContainer>
              <RadialIndicator
                {...getRadialConfig(
                  PassiveParameterTypeEnum.Compliance,
                  previousPeriodData ? previousPeriodData.daysTotal : 0
                )}
                size={200}
                value={previousPeriodData ? previousPeriodData.daysOfUse : 0}
                unit={`days of ${
                  previousPeriodData ? previousPeriodData.daysTotal : 0
                }`}
                disabled={
                  !previousPeriodData?.daysOfUse &&
                  !previousPeriodData?.daysTotal
                }
              />
              {previousPeriodData?.endTime && (
                <Typography sx={{ textAlign: "center" }}>
                  {`Previous Period`} <br /> {`Ended on`} <br />
                  {`${new Date(previousPeriodData.endTime).toLocaleDateString(
                    "en-US",
                    {
                      weekday: "short",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  )}`}
                </Typography>
              )}
            </ParamContentContainer>
          </Grid>

          <Grid item xs={6} container direction="column" alignItems="center">
            <ParamContentContainer>
              <RadialIndicator
                {...getRadialConfig(
                  PassiveParameterTypeEnum.Compliance,
                  currentPeriodData ? currentPeriodData.daysTotal : undefined
                )}
                size={200}
                value={
                  currentPeriodData ? currentPeriodData.daysOfUse : undefined
                }
                unit={`days of ${
                  currentPeriodData ? currentPeriodData.daysTotal : undefined
                }`}
                disabled={
                  !currentPeriodData?.daysOfUse && !currentPeriodData?.daysTotal
                }
              />
              {currentPeriodData?.endTime && (
                <Typography sx={{ textAlign: "center" }}>
                  {`Current Period`} <br /> {`Ending on`} <br />
                  {`${new Date(currentPeriodData.endTime).toLocaleDateString(
                    "en-US",
                    {
                      weekday: "short",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  )}`}
                </Typography>
              )}
            </ParamContentContainer>
          </Grid>
        </Grid>
      </Grid>
    </ParameterCard>
  );
};

export default ComplianceCard;
