import React from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import { showDialog } from "../../redux/selectors/errorSelectors";

interface ErrorDialogProps {
  handleClose: () => void;
}

const ErrorDialog: React.FC<ErrorDialogProps> = ({ handleClose }) => {
  const isDialogOpen = useSelector(showDialog);

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleClose}
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="error-dialog-title">A problem has occurred</DialogTitle>
      <DialogContent>
        <Typography sx={{ mt: 2 }} id="error-dialog-additional-text">
          We need to sign you out. Please sign in again.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
