import { createTheme } from "@mui/material/styles";
import { ReactComponent as PathInsightLogo } from "./assets/pathInsightLogo.svg";
import { Temperature } from "../../model/temperature";

export const walkwithpath = createTheme({
  clientId: "walkwithpath",
  productNames: {
    clientName: "Walk With Path",
    clientCode: "WWP",
    insoles: "Path Feel",
    dashboard: "Path Insight",
    app: "Path Feel App",
  },
  palette: {
    primary: {
      main: "#28666C",
    },
    secondary: {
      main: "#007bff",
    },
    alertUndefined: {
      main: "transparent",
    },
    alertLevel1: {
      main: "#ffc107",
      contrastText: "#fff",
    },
    alertLevel2: {
      main: "#ff9800",
      contrastText: "#fff",
    },
    alertLevel3: {
      main: "#f44336",
      contrastText: "#fff",
    },
    complianceUndefined: {
      main: "#FFF",
    },
    complianceNotAchievable: {
      main: "#808080",
    },
    complianceAtRisk: {
      main: "#D90B0A",
    },
    complianceOnTrack: {
      main: "#E46419",
    },
    complianceCompleted: {
      main: "#197F2B",
    },
  },
  metrics: {
    dateFormat: "en-GB",
    temperature: {
      unit: Temperature.Celsius,
      symbol: "°C",
    },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: "66px",
          minHeight: "66px",
          "@media(min-width:600px)": {
            minHeight: "66px",
          },
        },
      },
    },
  },
  typography: {
    fontFamily: ['"Montserrat"', "Open Sans"].join(","),
  },
  images: {
    mainLogo: PathInsightLogo,
  },
});
