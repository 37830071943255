import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import TableRow from "@mui/material/TableRow";
import { CurrentCompliancePeriod } from "../../model/compliance/CurrentCompliancePeriod";
import { CompliancePeriod } from "../../model/compliance/CompliancePeriod";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: "bold",
    //backgroundColor: theme.palette.common.black,
    //color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

function descendingComparator<Key extends keyof any>(
  a: {
    [key in Key]:
      | number
      | string
      | boolean
      | CurrentCompliancePeriod
      | CompliancePeriod
      | null;
  },
  b: {
    [key in Key]:
      | number
      | string
      | boolean
      | CurrentCompliancePeriod
      | CompliancePeriod
      | null;
  },
  orderBy: Key
) {
  const aValue = a[orderBy];
  const bValue = b[orderBy];

  if (aValue === null && bValue === null) return 0;
  if (aValue === null) return 1;
  if (bValue === null) return -1;

  if (typeof aValue === "object" && typeof bValue === "object") {
    // Assuming you want to compare based on daysOfUse
    return (aValue as CurrentCompliancePeriod).daysOfUse <
      (bValue as CurrentCompliancePeriod).daysOfUse
      ? -1
      : 1;
  }

  return aValue < bValue ? -1 : 1;
}

export type Order = "asc" | "desc";

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: {
    [key in Key]:
      | number
      | string
      | boolean
      | CurrentCompliancePeriod
      | CompliancePeriod
      | null;
  },
  b: {
    [key in Key]:
      | number
      | string
      | boolean
      | CurrentCompliancePeriod
      | CompliancePeriod
      | null;
  }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
