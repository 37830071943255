import {
  FETCH_COMPLIANCE_OVERVIEW,
  FETCH_COMPLIANCE_OVERVIEW_SUCCESS,
  FETCH_COMPLIANCE_OVERVIEW_FAILURE,
  FETCH_COMPLIANCE_PERIODS_FAILURE,
  FETCH_COMPLIANCE_PERIODS_SUCCESS,
  FETCH_COMPLIANCE_PERIODS,
  UPDATE_COMPLIANCE_PERIOD,
  UPDATE_COMPLIANCE_PERIOD_SUCCESS,
  UPDATE_COMPLIANCE_PERIOD_FAILURE,
  CLEAR_COMPLIANCE,
} from "../constants";
import { Dispatch } from "redux";
import { ComplianceActionTypes } from "../types/complianceActionTypes";
import complianceService from "../../services/ComplianceService";
import { CompliancePeriod } from "../../model/compliance/CompliancePeriod";

export function fetchComplianceOverview(): ComplianceActionTypes {
  return {
    type: FETCH_COMPLIANCE_OVERVIEW,
  };
}

export function fetchComplianceOverviewSuccess(data: any) {
  return {
    type: FETCH_COMPLIANCE_OVERVIEW_SUCCESS,
    payload: data,
  };
}

export function fetchComplianceOverviewError(
  error: string
): ComplianceActionTypes {
  return {
    type: FETCH_COMPLIANCE_OVERVIEW_FAILURE,
    payload: error,
  };
}

export function getComplianceOverview(patientGuid: string) {
  return function action(dispatch: Dispatch) {
    dispatch(fetchComplianceOverview());
    return complianceService.getOverview(patientGuid).then(
      (response: any) => dispatch(fetchComplianceOverviewSuccess(response))
      //error => dispatch(someerror('woody', buzz, error))
    );
  };
}

export function fetchCompliancePeriods(): ComplianceActionTypes {
  return {
    type: FETCH_COMPLIANCE_PERIODS,
  };
}

export function fetchCompliancePeriodsSuccess(data: any) {
  return {
    type: FETCH_COMPLIANCE_PERIODS_SUCCESS,
    payload: data,
  };
}

export function fetchCompliancePeriodsError(
  error: string
): ComplianceActionTypes {
  return {
    type: FETCH_COMPLIANCE_PERIODS_FAILURE,
    payload: error,
  };
}

export function getCompliancePeriods(patientGuid: string) {
  return function action(dispatch: Dispatch) {
    dispatch(fetchCompliancePeriods());
    return complianceService.getPeriods(patientGuid).then(
      (response: any) => dispatch(fetchCompliancePeriodsSuccess(response))
      //error => dispatch(someerror('woody', buzz, error))
    );
  };
}

export function updateCompliancePeriod(
  data: CompliancePeriod
): ComplianceActionTypes {
  return {
    type: UPDATE_COMPLIANCE_PERIOD,
    payload: data,
  };
}

export function updateCompliancePeriodSuccess(data: any) {
  return {
    type: UPDATE_COMPLIANCE_PERIOD_SUCCESS,
    payload: data,
  };
}

export function updateCompliancePeriodError(
  error: string
): ComplianceActionTypes {
  return {
    type: UPDATE_COMPLIANCE_PERIOD_FAILURE,
    payload: error,
  };
}

export function updatePatientCompliancePeriod(period: CompliancePeriod) {
  return function action(dispatch: Dispatch) {
    dispatch(updateCompliancePeriod(period));
    return complianceService.updatePeriod(period).then(
      (response: any) => dispatch(updateCompliancePeriodSuccess(response))
      //error => dispatch(someerror('woody', buzz, error))
    );
  };
}

export function clearComplianceData(): ComplianceActionTypes {
  return {
    type: CLEAR_COMPLIANCE,
  };
}
