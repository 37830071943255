import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Patient } from "../../../model/patient/patient";
import PatientDetailsHeader from "../../../components/Account/PatientDetailsHeader";
import BackButton from "../../../components/BackButton";
import ComplianceDetailTableComponent from "./ComplianceDetailTableComponent";
import { getCompliancePeriods } from "../../../redux/actions/complianceActions";
import { useAppThunkDispatch } from "../../../redux/configureStore";
import { useParams } from "react-router-dom";

const ComplianceDetail: React.FC = () => {
  const { patientId } = useParams();
  const [selectedPatient] = useState<Patient>();
  const dispatch = useAppThunkDispatch();

  useEffect(() => {
    if (patientId) {
      dispatch(getCompliancePeriods(patientId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <PatientDetailsHeader selectedPatient={selectedPatient} />
      <BackButton />

      <Typography variant="h5" sx={{ mt: 1, pr: 2 }}>
        <strong>Compliance</strong>
      </Typography>

      <Grid
        container
        direction="column"
        mt={1}
        spacing={2}
        alignItems="stretch"
      >
        <Grid item mr={4}>
          <ComplianceDetailTableComponent />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ComplianceDetail;
