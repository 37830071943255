import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { SettingsState } from "../../model/admin/settingsState";
import { SystemState } from "../../redux/system/SystemState";

interface ControlVisibilityProps {
  value:
    | keyof SettingsState["DailyMetricsVisibility"]
    | keyof SettingsState["DailyParametersVisibility"]
    | keyof SettingsState["OtherVisibility"];
  children: ReactNode;
}

const checkVisibility = (
  value: string,
  userVisibility: any,
  environmentVisibility: any,
  visibilityType:
    | keyof typeof userVisibility
    | keyof typeof environmentVisibility
) => {
  return (
    (value in userVisibility[visibilityType] &&
      userVisibility[visibilityType][value]) ||
    (!(value in userVisibility[visibilityType]) &&
      value in environmentVisibility[visibilityType] &&
      environmentVisibility[visibilityType][value])
  );
};
const ControlVisibility: React.FC<ControlVisibilityProps> = ({
  value,
  children,
}) => {
  const environmentVisibility = useSelector(
    (state: SystemState) => state.settings.environment
  );
  const userVisibility = useSelector(
    (state: SystemState) => state.settings.user
  );
  const isVisible =
    checkVisibility(
      value,
      userVisibility,
      environmentVisibility,
      "DailyMetricsVisibility"
    ) ||
    checkVisibility(
      value,
      userVisibility,
      environmentVisibility,
      "DailyParametersVisibility"
    ) ||
    checkVisibility(
      value,
      userVisibility,
      environmentVisibility,
      "OtherVisibility"
    );
  return isVisible ? <>{children}</> : null;
};
export default ControlVisibility;
