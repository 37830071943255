import { combineReducers } from "redux";

import accountReducer from "./accountReducer";
import activeReducer from "./activeReducer";
import adminReducer from "./adminReducer";
import dailyMetricsReducer from "./dailyMetricsReducer";
import dailyParametersReducer from "./dailyParametersReducer";
import errorReducer from "./errorReducer";
import passiveParametersReducer from "./passiveParametersReducer";
import patientsReducer from "./patientsReducer";
import settingsReducer from "./settingsReducer";
import { LOGOUT_USER_SUCCESS } from "../constants";
import complianceReducer from "./complianceReducer";

const appReducer = combineReducers({
  account: accountReducer,
  patients: patientsReducer,
  dailyMetrics: dailyMetricsReducer,
  dailyParameters: dailyParametersReducer,
  passiveParameters: passiveParametersReducer,
  active: activeReducer,
  admin: adminReducer,
  compliance: complianceReducer,
  settings: settingsReducer,
  error: errorReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === LOGOUT_USER_SUCCESS) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
