import {
  CLEAR_COMPLIANCE,
  FETCH_COMPLIANCE_OVERVIEW_SUCCESS,
  FETCH_COMPLIANCE_PERIODS_SUCCESS,
  UPDATE_COMPLIANCE_PERIOD,
} from "../constants";
import { initialState } from "../system/SystemState";
import { ComplianceActionTypes } from "../types/complianceActionTypes";
import { ComplianceState } from "../../model/admin/complianceState";
import { CompliancePeriod } from "../../model/compliance/CompliancePeriod";

export default function complianceReducer(
  state = initialState.compliance,
  action: ComplianceActionTypes
): ComplianceState {
  switch (action.type) {
    case FETCH_COMPLIANCE_OVERVIEW_SUCCESS:
      return {
        ...state,
        overview: action.payload,
      };

    case FETCH_COMPLIANCE_PERIODS_SUCCESS:
      return {
        ...state,
        periods: action.payload,
      };

    case UPDATE_COMPLIANCE_PERIOD:
      return {
        ...state,
        periods: state.periods.map((item: CompliancePeriod) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case CLEAR_COMPLIANCE:
      return {
        overview: {
          currentPeriod: null,
          previousPeriod: null,
        },
        periods: [],
      };

    default:
      return state;
  }
}
