import { SystemState } from "../system/SystemState";

export const environmentDailyMetricsVisibilitySettings = (state: SystemState) =>
  state.settings.environment.DailyMetricsVisibility;
export const environmentDailyParametersVisibilitySettings = (
  state: SystemState
) => state.settings.environment.DailyParametersVisibility;
export const environmentOtherVisibilitySettings = (state: SystemState) =>
  state.settings.environment.OtherVisibility;

export const userDailyMetricsVisibilitySettings = (state: SystemState) =>
  state.settings.user.DailyMetricsVisibility;
export const userDailyParametersVisibilitySettings = (state: SystemState) =>
  state.settings.user.DailyParametersVisibility;
export const userOtherVisibilitySettings = (state: SystemState) =>
  state.settings.user.OtherVisibility;
